<template>
  <div class="layout">
    <!-- <el-backtop>
   <i class="el-icon-caret-top"></i>
  </el-backtop> -->

    <div class="navbar">
      <a href="/index" style="margin-left: 1vw;">
        <img src="../assets/logo2.png" width="281px" height="69px" />
      </a>
      <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#fff" text-color="black" active-text-color="#00bdff" hover="#00bdff" router>
        <el-menu-item index="/index">主页</el-menu-item>
        <!-- <el-menu-item  index='/product'>产品中心</el-menu-item> -->
        <el-menu-item index="/solution">方案介绍</el-menu-item>
        <el-menu-item index="/service">服务支持</el-menu-item>
        <el-menu-item index="/Journalism">新闻中心</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
      <!-- <button class="login">平台登录</button> -->
    </div>
    <router-view></router-view>
    <div class="right">
      <div class="item green" @click="weixinchange">
        <a href="javascript:;" title="关注微信公众号">
          <img src="../assets/index/weixin.png" width="70%">
        </a>
        <div class="weix" v-show="show">
          <img style="width:200px" src="../assets/ce0d9e75b77d7efb9234a479a1e227c.jpg">
        </div>
      </div>
      <div class="item ico" @click="runScroll">
        <a href="#">
          <img src="../assets/index/xiangshangjiantou.png" width="70%">
        </a>
      </div>
    </div>
    <div class="footer">
      <!-- <div class="item-box">
        <div class="item">
          <div class="tit">网站导航</div>
          <div class="box">
            <div class="test" @click="Jump('/')"><a href="javascript:">主页</a></div>
            <div class="test" @click="Jump('/monitor')"><a href="javascript:">配电房监控</a></div>
            <div class="test" @click="Jump('/solution')"><a href="javascript:">解决方案</a></div>
            <div class="test" @click="Jump('/about')"><a href="javascript:">关于我们</a></div>
             <div class="test" >资质证书</div>
             <div class="test" @click="Jump('/yun')"><a href="javascript:">物联网平台</a></div>
            <div class="test" @click="Jump('/product')"><a href="javascript:">产品中心  </a></div>
            <div class="test" @click="Jump('/service')"><a href="javascript:">服务支持</a></div>
            <div class="test" @click="Jump('/about')"><a href="javascript:">公司简介</a></div>
          </div>
        </div>
        <div class="item">
          <div class="tit">公司地址</div>
          <div class="box">
            <div class="test">{{this.data.company_name}} 版权所有</div>
            <div class="test">公司地址: {{this.data.company_addr}}</div>
            <div class="test">技术支持: 地球村物联科技</div>
            <div class="test">网址: {{this.data.company_site}}</div>
          </div>
        </div>
        <div class="item">
          <div class="tit">联系方式</div>
          <div class="box">
            <div class="test">
              <a href="tencent://message/?uin=77673175&Menu=yes" target="blank">客服QQ号码</a>
               </div>
            <div class="test">邮箱: {{this.data.company_email}}</div>
            <div class="test">邮编: {{this.data.company_postcode}}</div>
          </div>
        </div>
        <div class="item">
          <img src="~images/dqcerweima.png" alt="" />
        </div>
      </div>  -->
      <el-row class="foot">
        <el-col :span="20">
          <img src="../assets/logo.png" style="left: -32%;position: relative;">
          <div class="tex">公司电话：{{ this.data.company_tel }}</div>
          <div class="tex">公司地址：{{ this.data.company_addr }}</div>
          <div class="tex">总部地址：{{ this.data.group_addr }}</div>
          <div class="tex">公司邮箱: {{ this.data.company_email }}</div>
        </el-col>
        <el-col :span="4">
          <img style="width:150px" src="../assets/ce0d9e75b77d7efb9234a479a1e227c.jpg">

        </el-col>
        <el-col :span="24" class="beian">
          <a href="https://beian.miit.gov.cn/">版权所有 © 中海创水务科技（福建）有限公司 － 闽ICP备2021019052号</a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getCompanyList } from "api/company";
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      data: [],
      show: false,
    };
  },
  methods: {
    weixinchange() {
      this.show = !this.show
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    runScroll() {
      scrollTo(document.body, 0, 600);
    },

    scrollTo(element, to, duration) {
      if (duration <= 0) return;
      var difference = to - element.scrollTop;
      var perTick = difference / duration * 10;

      setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop == to) return;
        scrollTo(element, to, duration - 10);
      }, 10);
    },
    getList() {
      getCompanyList()
        .then((res) => {
          this.data = res.data.entity;
          console.log(this.data)
        });
    },
    Jump(index) {
      this.$router.push({ path: index })
    }

  },
  mounted() {
    this.getList()
  }
};
</script>

<style lang="scss" scoped>
.right {
  right: 0;
  bottom: 40%;
  position: fixed;
  z-index: 10;

  .item {
    width: 40px;
    height: 40px;
  }

  .orange {
    background-color: orange;

    img {
      margin-top: 5px;
    }
  }

  .blue {
    background-color: #3da8e5;

    img {
      margin-top: 5px;
    }
  }

  .green {
    background-color: #24b727;

    img {
      margin-top: 5px;
    }

    .weix {
      width: auto;
      max-width: 200px;
      left: auto;
      right: 100%;
      bottom: 0;
      position: absolute;
      -webkit-transition: all 0s ease 0s;
      -moz-transition: all 0s ease 0s;
      -ms-transition: all 0s ease 0s;
      -o-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
    }
  }

  .ico {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: rgba(30, 30, 30, .6);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% auto;
    text-align: center;
    left: 0;
    top: 0;

    img {
      margin-top: 5px;
    }
  }

  .ico1 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% auto;
    text-align: center;
    left: 0;
    top: 0;
  }
}

.bei {
  background: #2c3e50;

  a {
    color: #fff;
  }
}

// .am {
//   padding: 0 auto;
// }
a {
  color: #666;
  text-decoration: none;
}

.layout {
  .navbar {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding: 0 auto;
    justify-content: flex-start;
    // justify-content: space-evenly;
    height: 80px;

    img {
      margin: 0 40px 0 0;
    }

    .tit {
      font-size: 24px;
      color: #4da9ff;
      margin: 0 50px 0 0;
      // margin: 0 auto;
      min-width: 313px;
    }

    .login {
      color: black;
      border-radius: 30px;
      font-size: 15px;
      width: 99px;
      border: 1px solid black;
      height: 50px;
      outline: none;
      box-shadow: none;
      background: white;
      margin: 0 50px;
    }
  }

  .footer {
    width: 100%;
    height: 240px;
    padding: 50px 0 0 0;
    position: relative;
    bottom: 0;
    background-color: rgb(27, 26, 26);

    .item-box {
      display: flex;
      justify-content: center;

      .item {
        margin-top: 30px;
        margin-left: 40px;

        .tit {
          color: white;
          font-size: 18px;
          text-align: left;

        }

        .wximg {
          max-width: 100px;
          max-height: 100px;
        }

        .box {
          display: flex;
          flex-direction: column;
          text-align: left;
          flex-wrap: wrap;
          height: 160px;
          width: 200px;
          color: #666;
          margin: 10px 0;

          .test {
            margin: 1px 0;
          }
        }
      }

      .item:nth-child(1) {
        border-right: 1px solid #666;
      }

      .item:nth-child(2) {
        border-right: 1px solid #666;
      }
    }
  }
}

.tex {
  margin-left: 9%;
  text-align: left;
  color: #A4ADBA;
}

.are {
  /* 这里高度减60像素减的是顶部导航条的高度。避免出现浏览器自带的滚动条 */
  height: 60px;
  width: 60px;
  /* 溢出高度自动显示滚动条 */
  overflow: auto;
}

.beian {
  margin-top: 20px;
}
</style>

<style>
.el-menu.el-menu--horizontal {
  border: none;
}
</style>